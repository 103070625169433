import './controls.scss';
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import SensorBlock from 'components/pages/controls/sensorBlock';
import InfoToast from './global/toasts';
import { ControlLight } from 'components/pages/controls/controlLights.js';
import { ControlPump } from 'components/pages/controls/controlPump.js';
import VirtualFarm from 'components/pages/controls/farm.js';
import Button from './global/buttons';
import useWindowWidth from 'components/functions/utils/useWindowWidth';

function Controls() { 
  useEffect(() => {
    document.getElementById("content").scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation('controls');
  const { isMobile } = useWindowWidth();
  const {accessToken} = useSelector(state => state.auth);

  const [toastVisible, setToastVisible] = useState(false);
  const handleToastChange = (isVisible) => {
    setToastVisible(isVisible);
  };

  const itemsNutrients = [
    { svg: "🌹", label: t("actuators.flowering") },
    { svg: "🌱", label: t("actuators.vegetative") }
  ];
  
  const itemsInOut = [
    { svg: "⬇️", label: t("actuators.in")  },
    { svg: "⬆️", label: t("actuators.out")  }
  ];

  return (
      <>
      {/* <div className="controls flex h-full w-full items-center justify-center"> */}
        <div className="conditions-wrapper">
          <VirtualFarm accessToken={accessToken}/>
          <div className="sensors-wrapper">
            <div className="header">
              <h3 className="m0">{t('liveConditions')}</h3>
              {!isMobile && <Button type='secondary' color='purple' label={t('seeAnalytics')} onClick={() => { navigate("/analytics"); }}/>}
            </div>
            <div className="sensors-container">
              <div className="sensors">
                <SensorBlock icon="🌿" keys_name='co2' name={t("sensors.sensors_co2")} unit=" ppm" accessToken={accessToken}/>
                <SensorBlock icon="🌧️" keys_name='humidity' name={t("sensors.sensors_humidity")} unit="%" accessToken={accessToken}/>
                <SensorBlock icon="🌡️" keys_name='temperature' name={t("sensors.sensors_temperature")} unit="°C" accessToken={accessToken}/>
                <SensorBlock icon="🧪" keys_name='ph' manualvalue='6.9' name={t("sensors.sensors_ph")} unit="pH" accessToken={accessToken}/>
                <SensorBlock icon="⚡" keys_name='ec' manualvalue='1.2' name={t("sensors.sensors_ec")} unit="μS/cm" accessToken={accessToken}/>
                <SensorBlock icon="💧" keys_name='water_level' manualvalue='65' name={t("sensors.sensors_water_level")} unit="%" accessToken={accessToken}/>
                {/* <SensorBlock icon="💧" keys_name='water_level' name={t("sensors.sensors_water_level")} unit="" accessToken={accessToken}/> */}
              </div>
            </div>
            {isMobile && <Button type='secondary' color='purple' label={t('seeAnalytics')} className="mobile-full" onClick={() => { navigate("/analytics"); }}/>}
        </div>
        </div>
        <div className="controls-wrapper">
          <div className="header">
            <h3 className="m0">{t('controls')}</h3>
            {!isMobile && <Button type='secondary' color='purple' label={t('programTinyfarm')} onClick={() => { navigate("/programming"); }}/>}
          </div>
          <div className="controls">
            <ControlLight 
              onToastChange={handleToastChange}
            />                
            <ControlPump name={t('water')} keys_name="water" commandName="water" items={itemsInOut} id="water-pump" state='inactive' icon="🚿" unit='ml' onToastChange={handleToastChange}  min={0} max={250}/>
            <ControlPump name={t('nutrients')} keys_name="nutrients" commandName="nutrition" items={itemsNutrients} id="nutrients-pump" state='inactive' icon="⚡" unit='ml' onToastChange={handleToastChange} min={0} max={50}/>
            <ControlPump name={t('air')} keys_name="air" commandName="air" items='' id="air-pump" state='inactive' icon="🍃" unit={t('units.seconds')} onToastChange={handleToastChange} min={0} max={60}/>
          </div>
          {isMobile && <Button type='secondary' color='purple' label={t('programTinyfarm')} className="mobile-full" onClick={() => { navigate("/programming"); }}/>}
        </div>
      {/* </div> */}

      {/* Toasts */}
      {toastVisible && <InfoToast emoji="⚠️" color="red" message={t('toasts.error_send_instruction')} actions={[{action: () => setToastVisible(false)}]} />}
      </>
  );
}

export default Controls;

